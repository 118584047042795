
// 切割key
export function splitKey(item, key) {
    let arr = key.split('.');
    let length = arr.length;
    let init = 0;
    if (length > 1 && init < length) {
        let new_arr = arr.slice(1);
        return splitKey(item[arr[init++]], new_arr.join())
    } else {
        return {
            new_item: item[0],
            new_key: arr[0]
        }
    }

}

// 检验表单数据，通过 true 不通过 false
// form 表单数据 rules 规则 source 数据源
export function isValidate(form, rules, source) {
    let validate = true;
    for (let key in form) {
        if (Array.isArray(source)) {
            const {
                new_key
            } = splitKey(source, key);
            if (rules[new_key] && rules[new_key][0].required && !form[key]) {
                validate = false;
                break;
            }
        }
        if (rules[key] && rules[key][0].required && !form[key]) {
            validate = false;
            break;
        }
    }
    return validate
}